import React, {useState, useEffect} from 'react';
import './App.css';
import axios from 'axios';
import About from './Components/About';
import Banner from './Components/Banner';
import CatchUpCTA from './Components/CatchUpCTA';
import CtaOne from './Components/CtaOne';
import Footer from './Components/Footer';
import FooterForm from './Components/FooterForm';
import Header from "./Components/Header";
import LastCTA from './Components/LastCTA';
import Offers from './Components/Offers';
import Process from './Components/Process';
import Services from './Components/Services';
import Testimonials from './Components/Testimonials';
import { ModalSection } from './Components/ModalSection';

function App() {
  useEffect(() => {
    // Call the Laravel API using axios
    axios.get('https://serverlanes.com/api/chat-script')  // Update with the correct route
      .then(response => {
        const data = response.data;

        // Check if the chat script should be loaded
        if (data.enableChat) {
          // Dynamically create a script element and set its content
          const script = document.createElement('script');
          script.src = "https://static.zdassets.com/ekr/snippet.js?key=af874e8f-0d84-41a3-b4a5-61878c971961";
          script.id = "ze-snippet";
          document.head.appendChild(script); // Append to head
        }
      })
      .catch(error => {
        console.error('Error loading chat script:', error);
      });
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="App">
      <Header onOpenModal={handleOpenModal} />
      <Banner onOpenModal={handleOpenModal} />
      <About />
      <CtaOne onOpenModal={handleOpenModal} />
      <Services onOpenModal={handleOpenModal} />
      <Offers />
      <CatchUpCTA onOpenModal={handleOpenModal} />
      <Process />
      <LastCTA onOpenModal={handleOpenModal} />
      <Testimonials />
      <FooterForm />
      <Footer />
      <ModalSection open={isModalOpen} onCloseModal={handleCloseModal} />
    </div>
  );
}

export default App;
